let resumeData = {
  "imagebaseurl": "",
  "name": "Purushottam Dhakal",
  "role": "",
  "linkedinId": "",
  "skypeid": "",
  "roleDescription": "",
  "socialLinks": [
    {
      "name": "Twitter",
      "url": "#",
      "className": "fa fa-twitter"
    }, 
    {
      "name": "Facebook",
      "url": "#",
      "className": "fa fa-facebook"
    },  
    {
      "name": "tiktok",
      "url": "#",
      "className": "fa fa-brands fa-tiktok"
    },  
    {
      "name": "Gmail",
      "url": "mailto:purushottamdhakal051@gmail.com",
      "className": "fa fa-envelope"
    }

  ],
  "aboutme": "I am currently a filed officer at chitwan district Cooperative Union. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
  "address": "Bharatpur-13,Chitwan,Nepal",
  "website": "purushottamdhakal.com.np",
  "phone":"+977-9845397514",
  "education": [
    {
      "UniversityName": "HSEB(+2)",
      "specialization": "Business organazation office management",
      "MonthOfPassing": "2008",
      "YearOfPassing": "- 2010",
      "Achievements": ""
    },
    {
      "UniversityName": "Tribhuwan University(BBS)",
      "specialization": "Bachelor Degree in Business studies",
      "MonthOfPassing": "2010",
      "YearOfPassing": "- 2013",
      "Achievements": "Account,Human Resource Management,Tax,Economice,English,Business Law"
    },
    {
      "UniversityName": "Tribhuwan University(MBS)",
      "specialization": "Masters Degree in Business studies",
      "MonthOfPassing": "2013",
      "YearOfPassing": "- 2019",
      "Achievements": "Account,Producation,tax,finance,Marketing,HumanResources Management"
    }
  ],
  "work": [
    {
      "CompanyName": "Manakamana Saving and Credit Co-Operative limited",
      "specialization": "Manager",
      "MonthOfLeaving": "Jan",
      "YearOfLeaving": "2019",
      "Achievements": ""
    },
    {
      "CompanyName": "Chitwan District Saving and Credit Co-Operative Union ltd",
      "specialization": "Field Officer",
      "MonthOfLeaving": "",
      "YearOfLeaving": "",
      "Achievements": "Supervision all Sector departements"
    }
  ],
  "skillsDescription": "",
  "skills": [
    {
      "skillname": "Communication",
      "description":"Language : Nepali,English,Hindi"
    },
    {
      "skillname": "Organisational skills",
      "description":"Ability : Technical ability,Quick Learner"
    },
    {
      "skillname": "Job-related skills",
      "description":"Goal-oriented and well-organized"
    },
    {
      "skillname": "Digital skills",
      "description":"Microsoft Office, Email and Internet, Social communication"
    }
  ],
  "portfolio": [
    {
      "name": "project1",
      "description": "mobileapp",
      "imgurl": "images/portfolio/phone.jpg"
    },
    {
      "name": "project2",
      "description": "mobileapp",
      "imgurl": "images/portfolio/project.jpg"
    },
    {
      "name": "project3",
      "description": "mobileapp",
      "imgurl": "images/portfolio/project2.png"
    },
    {
      "name": "project4",
      "description": "mobileapp",
      "imgurl": "images/portfolio/phone.jpg"
    }
  ],
  "testimonials": [
    {
      "description": "We are very satisfied with the inspectipn/audit done by Purushottam Sir.",
      "name": "Rastriya Saccos",
      "website":"https://rastriyasaccos.coop.np/",
      "web_2":"https://build.rastriyasaccos.coop.np/"
    },
    {
      "description": "This is a sample testimonial",
      "name": "Some technical guy",
      "website":"#",
      "web_2":"#",
    }
  ]
}

export default resumeData