import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div>
          <iframe 
             style={{"height":"calc(100vh - 200px)","width":'100%'}}
             src="https://script.google.com/macros/s/AKfycbyT2tvmD8SKIm_j6Q36GBz8W6IV42Cyc2qA7shjjYPlQ8k-dmBufae8uYY73zd65Inh_g/exec"></iframe>
        </div>
      </section>
    );
  }
}